import React, { useState } from "react";
import env from "./models/Configuration";
import _ from "lodash";
import { AuthProvider as OIDCAuthProvider } from 'react-oidc-context';
import { User } from "oidc-client-ts";
import LoginIAM from 'loginIAM';
import { featureFlagNames } from 'services/appConfigService';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from 'authConfig';
import LoginB2C from 'loginB2C';
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import * as appConfigService from 'services/appConfigService';
import { oidcInstance } from "authConfigIAM";

const AuthProvider = () => {

 const [isIAMAuthentication, setIsIAMAuthentication] = useState(undefined);

  useEffect(() => {
    loadIAMFeatureFlag();
  }, [])

  const loadIAMFeatureFlag = async () => {
    const isIAMAuthentication = await appConfigService.getConfigByKey(featureFlagNames.IAMAuthentication);
    setIsIAMAuthentication(isIAMAuthentication);
  }

  const onSigninCallback = async (user: void | User) => {
    if (user) {
      loadIAMFeatureFlag();
    }
  };

  return (
    isIAMAuthentication == undefined
      ? <Spinner id="page-spinner" animation="border" variant="warning" />
      : isIAMAuthentication == true
      ? <OIDCAuthProvider userManager={oidcInstance} onSigninCallback={onSigninCallback}><LoginIAM /></OIDCAuthProvider>
      : <MsalProvider instance={msalInstance}><LoginB2C /></MsalProvider>
  );
}

export default AuthProvider;

